exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiatives-js": () => import("./../../../src/pages/initiatives.js" /* webpackChunkName: "component---src-pages-initiatives-js" */),
  "component---src-pages-investorlogin-js": () => import("./../../../src/pages/investorlogin.js" /* webpackChunkName: "component---src-pages-investorlogin-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

